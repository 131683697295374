import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import ToastCustom from "../../../../Components/Common/Toast";
import {
  getAPIGetArticleLiveById,
  getAPIListAuthor,
  getAPIPostLiveContentArticle,
  getAPIRoleByArticleId,
  getAPIRoleByArticleLiveId,
} from "../../../../helpers/fakebackend_helper";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { RequiredIcon } from "../../../../Components/Common/RequiredIcon";
import { convertVietnamese } from "../../../../helpers/text_helper";
import { Button, DatePicker, Image, Select } from "antd";
import { Editor } from "@tinymce/tinymce-react";
import CustomModal from "../../Article/UpdateArticleType/ModelStepsInfo.component";
import ModalStep from "./ModalStep";
import ModalAddMedial from "../../Article/UpdateArticleType/ModalAddMedia";
import { v4 as uuidv4 } from "uuid";
import SelectMedia from "../../FileManager/FileManagerMedia/SelectMedia";

const AddContent = () => {
  const [avatar, setAvatar] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [valueCategory, setValueCategory] = useState();
  const [optionsAuthor, setOptionsAuthor] = useState([]);

  const [valueAuthor, setValueAuthor] = useState([]);
  const [articleTitle, setArticleTitle] = useState("");
  const [articleStatus, setArticleStatus] = useState("");
  const [articleStatusIsComplete, setArticleStatusIsComplete] = useState(false);
  const [articleCreatedBy, setArticleCreatedBy] = useState("");

  const { id } = useParams();
  const editorRef = useRef(null);
  const initContent = `<p> Nhập nội dung bài viết.</p>`;
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [listUser, setListUser] = useState();
  const [isFirst, setFirst] = useState(true);
  const [article, setArticle] = useState();

  const [contentTitle, setContentTitle] = useState("");
  const [contentDisplayTime, setContentDisplayTime] = useState("");

  const [dataMedia, setDataMedia] = useState([]);
  const [isOpenModalAddImage, setOpenModalAddImage] = useState(false);
  const [listImage, setListImage] = useState([]);

  function onChangeAuthor(value) {
    if (value === undefined) {
      value = null;
    }
    setValueAuthor(value);
  }

  function addImageLink(e, id, type, originUrl, width) {
    if (type === "image") {
      const imageLink = `${e}`;
      editorRef.current.insertContent(`
          <figure class="image" style="width: ${width};" id="add_image_link">
            <img width="100%" src=${imageLink} />
            <figcaption></figcaption>
          </figure>
    `);
    }
    if (type === "audio") {
      const imageLink = `/` + encodeURIComponent(originUrl);
      editorRef.current.insertContent(`
          <figure>
          <audio
            controls
            src=${imageLink}
          >
          </audio>
        </figure>
      `);
    }
    if (type === "video") {
      const imageLink = `/` + encodeURIComponent(originUrl);
      editorRef.current.insertContent(`
          <video id="my-video"
          class="video-js"
          controls
          preload="auto"
          width="640"
          height="264"
          poster="MY_VIDEO_POSTER.jpg"
          data-setup="{}">
            <source src=${imageLink} type='video/mp4'>
          </video>
    `);
    }
  }

  let navigate = useNavigate();

  useEffect(() => {
    getAPIListAuthor().then((res) => {
      if (res.data && res.status > 0) {
        var options = [];
        res.data.forEach((e) => {
          options.push({
            value: e.user_id,
            // value: e.author_name,
            label: e.author_name,
          });
        });
      }
      setOptionsAuthor(options);
    });

    getAPIGetArticleLiveById(id).then((res) => {
      if (res.data && res.status > 0) {
        const ar = res.data;
        setArticleTitle(ar.article_title);
        setArticleStatus(ar.status);
        setArticleCreatedBy(ar.user_create_article);
        if (ar.article_status_id === 11) {
          setArticleStatusIsComplete(true);
        }
      }
    });
    getAPIRoleByArticleLiveId().then((res) => {
      if (res.data && res.status > 0) {
        const role = res.data;
        const currentStep = role._role_steps_info[1];
        setListUser(currentStep.list_user_accept);
      }
    });
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      article_title: "", //article_content!=undefined?"":article_content.article_title,
    },

    onSubmit: (values) => {
      const newarticle = {
        article_id: id,
        article_title: contentTitle,
        article_content: editorRef.current.getContent(),
        start_time: dayjs(fromDate).toISOString(),
        display_time: contentDisplayTime,
        other_author: "[" + valueAuthor.toString() + "]",
        article_files:
          listImage.length > 0
            ? JSON.stringify(listImage.map((e) => e.id))
            : "",
        // next_user_accept_id: "string"
      };

      let result = { ...newarticle };

      // save new articles
      if (!result.article_content) {
        ToastCustom("Mời bạn nhập nội dung bài viết", "fail");
      }
      if (!result.start_time) {
        ToastCustom("Mời bạn chọn thời gian diễn biến", "fail");
      }
      if (!result.article_title) {
        ToastCustom("Mời bạn chọn tiêu đề diễn biến", "fail");
      }
      if (!result.display_time) {
        ToastCustom("Mời bạn chọn thời gian hiển thị", "fail");
      } else {
        getAPIPostLiveContentArticle(result).then((r) => {
          if (r.status > 0) {
            ToastCustom("Thêm diễn biến thành công", "success");
            validation.resetForm();
            navigate(`/list-article-live-content/${id}`);
          } else if (r.status < 0) {
            ToastCustom(
              r.message ? r.message : "Thêm diễn biến thất bại",
              "fail"
            );
          }
        });
      }
    },
  });

  const onChangeFromDate = (e) => {
    setFromDate(e);
  };

  return (
    <div>
      <ModalAddMedial
        dataMedia={dataMedia}
        isOpen={isOpenModalAddImage}
        addImageLink={addImageLink}
        handleModalClose={() => {
          setOpenModalAddImage(false);
        }}
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Thêm mới diễn biến"
            pageTitle="Danh sách bài diễn biến"
          />

          <Row>
            <Card style={{ padding: 15 }}>
              <Row>
                <Col className="col-6">
                  <p
                    style={{ fontSize: 18, fontWeight: 600, marginBottom: 10 }}
                  >
                    {articleTitle}
                  </p>
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      color: !articleStatusIsComplete ? "#0AB39C" : "red",
                      fontStyle: "italic",
                    }}
                  >
                    {articleStatus}
                  </p>
                </Col>
                <Col className="col-6">
                  <p
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      color: "#5E5E5E",
                      marginBottom: 10,
                    }}
                  >
                    Người tạo bài:
                  </p>
                  <p
                    style={{
                      fontSize: 15,
                      fontWeight: 200,
                      color: "#5E5E5E",
                      marginBottom: 0,
                    }}
                  >
                    {articleCreatedBy || "anonymous"}
                  </p>
                </Col>
              </Row>
            </Card>

            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">
                          Tiêu đề
                          <RequiredIcon />
                        </Label>
                        <Input
                          name="article_title"
                          className="form-control"
                          placeholder="Nhập tiêu đề diễn biến"
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => {
                            // eslint-disable-next-line no-unused-expressions
                            validation.values.article_title = e.target.value;
                            setContentTitle(e.target.value);
                          }}
                          // value={validation.values.article_title || ""}
                          invalid={
                            validation.touched.article_title &&
                            validation.errors.article_title
                              ? true
                              : false
                          }
                        />
                        {validation.touched.article_title &&
                        validation.errors.article_title ? (
                          <FormFeedback type="invalid">
                            {validation.errors.article_title}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="article_content" className="form-label">
                          Nội dung diễn biến
                          <RequiredIcon />
                        </Label>
                        <Editor
                          onClick={() => {
                            if (
                              isFirst ||
                              editorRef.current.getContent() === ""
                            ) {
                              setFirst(false);
                              editorRef.current.formatter.register(
                                "customStyle",
                                {
                                  inline: "span",
                                  styles: {
                                    fontSize: "18px",
                                    lineHeight: "1.6",
                                  },
                                }
                              );

                              editorRef.current.formatter.apply("customStyle");
                            }
                          }}
                          onEditorChange={(content, editor) => {
                            if (!content) {
                              editorRef.current.formatter.register(
                                "customStyle",
                                {
                                  inline: "span",
                                  styles: {
                                    fontSize: "18px",
                                    lineHeight: "1.6",
                                  },
                                }
                              );

                              editorRef.current.formatter.apply("customStyle");
                            }
                          }}
                          apiKey="g4pmwp4e5kcjz0cum1z0vz2h0tl5vnjwc5ou58yj82sp4kbf"
                          tinymceScriptSrc={
                            process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"
                          }
                          onInit={(evt, editor) => {
                            editorRef.current = editor;
                            //editor.setContent(initContent)
                          }}
                          initialValue={initContent}
                          selector={"textarea"}
                          init={{
                            height: 500,
                            selector: "textarea",
                            menubar: ["tools", "view"],
                            // language: "vi",
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "preview",
                              "help",
                              "wordcount",
                            ],
                            font_size_formats:
                              "8px 10px 12px 14px 16px 18px 24px 36px 48px",
                            line_height_formats:
                              "1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2",
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help" +
                              "code" +
                              "fullscreen",
                            content_style:
                              "body { font-family:Mulish, Helvetica, Arial, sans-serif;}" +
                              `.wrong-word{
                              color:red;
                              cursor: pointer;
                            }`,
                            font_formats:
                              "Mulish, Helvetica,Arial, sans-serif;", // Định nghĩa font chữ mặc định là Roboto
                            formats: {
                              customFormat: {
                                inline: "span",
                                styles: {
                                  "font-size": "14px",
                                  "font-family":
                                    "Mulish, Helvetica,Arial, sans-serif",
                                },
                              },
                            },
                            style_formats: [
                              { title: "14px Mulish", format: "customFormat" },
                            ],
                            font_family_formats: "",
                            // font_family_formats:
                            //   "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago;Roboto=roboto; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                          }}
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">
                          Thời gian bắt đầu diễn biến
                          <RequiredIcon />
                        </Label>
                        <Col className="col-12">
                          <DatePicker
                            id="start_date"
                            allowClear
                            style={{ width: "100%" }}
                            onChange={onChangeFromDate}
                            placeholder="Từ ngày"
                            showTime
                          />
                        </Col>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">
                          Thời gian hiển thị trên website/app
                          <RequiredIcon />
                        </Label>
                        <Input
                          name="article_display_time"
                          className="form-control"
                          placeholder="Nhập thời gian hiển thị"
                          type="text"
                          validate={{
                            required: { value: true },
                          }}
                          onChange={(e) => {
                            validation.values.article_display_time =
                              e.target.value;
                            setContentDisplayTime(e.target.value);
                          }}
                          invalid={
                            validation.touched.article_display_time &&
                            validation.errors.article_display_time
                              ? true
                              : false
                          }
                        />
                        {validation.touched.article_display_time &&
                        validation.errors.article_display_time ? (
                          <FormFeedback type="invalid">
                            {validation.errors.article_display_time}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="article_author" className="form-label">
                          Tác giả
                        </Label>
                        <Select
                          style={{
                            width: "100%",
                          }}
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          allowClear
                          options={optionsAuthor}
                          // treeDefaultExpandAll
                          placeholder="Có thể chọn nhiều tác giả"
                          optionFilterProp="label"
                          mode="multiple"
                          showSearch
                          treeNodeFilterProp="title"
                          value={valueAuthor}
                          onChange={onChangeAuthor}
                        />
                      </div>

                      <div
                        className="hstack gap-2 justify-content-end"
                        style={{ marginLeft: "0px!important" }}
                      >
                        <button type="button" className="btn btn-light">
                          <Link to={`/list-article-live-content/${id}`}>
                            Quay lại
                          </Link>
                        </button>

                        <button type="submit" className="btn btn-success">
                          Lưu diễn biến
                        </button>
                        <button
                          type="button"
                          onClick={() => setmodal_scroll(true)}
                          className="btn btn-primary"
                        >
                          Gửi duyệt
                        </button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4}>
                <Card>
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <Label>Media trong bài viết</Label>
                    <SelectMedia
                      title="Thư viện media"
                      onUploadMedia={(url, id, type, originUrl) => {
                        setListImage([
                          ...listImage,
                          { url, id, type, originUrl },
                        ]);
                      }}
                      className={"btn btn-success"}
                    ></SelectMedia>
                  </CardHeader>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "wrap",
                        gap: "5px",
                      }}
                    >
                      {listImage.map((e) => {
                        return (
                          <div key={uuidv4()}>
                            <Image src={e.url} width={167} height={167}></Image>
                            <div style={{ padding: 10 }}>
                              <Button
                                onClick={() => {
                                  setListImage(
                                    listImage.filter(
                                      (element) => element.id !== e.id
                                    )
                                  );
                                }}
                              >
                                Hủy
                              </Button>
                              <Button
                                type="primary"
                                style={{ marginLeft: 10 }}
                                onClick={() => {
                                  setOpenModalAddImage(true);
                                  setDataMedia([
                                    e.url,
                                    e.id,
                                    e.type,
                                    e.originUrl,
                                  ]);
                                }}
                              >
                                Thêm
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
          <ModalStep
            togScroll={() => setmodal_scroll(!modal_scroll)}
            modalScroll={modal_scroll}
            listUser={listUser}
            article={{
              article_id: id,
              article_title: contentTitle,
              article_content:
                editorRef?.current !== null && editorRef?.current.getContent(),
              start_time: fromDate && dayjs(fromDate).toISOString(),
              display_time: contentDisplayTime,
              other_author: "[" + valueAuthor.toString() + "]",
              article_files:
                listImage.length > 0
                  ? JSON.stringify(listImage.map((e) => e.id))
                  : "",
            }}
          />
        </Container>
      </div>
    </div>
  );
};
export default AddContent;
